
import { defineComponent, toRaw } from 'vue'
import { Driver } from '@/shared/interfaces/driver.interface'
import { DarkStoresActions } from '@/store/modules/darkstores/actions'
import { DarkStoreInterface } from '@/shared/interfaces/darkstore.interface'
import { DriverActions } from '@/store/modules/drivers/actions'
import { Order } from '@/shared/interfaces/order.interface'
import router from '@/router'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import moment from 'moment'

let markerCluster: any

const wdw: any = window
// const driverMarkers: any = []
export default defineComponent({
  name: 'DriversMap',
  props: ['selectedDriver', 'locations'],
  data () {
    return {
      openedMarkerID: 1,
      mapTypeControl: true,
      map: {} as any,
      activeInfoWindow: null as any,
      driversLocations: null as any,
      darkstoresState: null as any,
      routePaths: {} as any,
      polylines: {} as any,
      selectedDriverId: null,
      markers: {} as any
      /*
      testTrip: [
        { lat: 33.315100, lng: 44.365722 },
        { lat: 33.317118, lng: 44.370609 },
        { lat: 33.319769, lng: 44.367161 },
        { lat: 33.322387, lng: 44.364862 },
        { lat: 33.329226, lng: 44.353747 },
        { lat: 33.328939, lng: 44.339318 },
        { lat: 33.314451, lng: 44.338115 },
        { lat: 33.303835, lng: 44.341551 },
        { lat: 33.299101, lng: 44.354090 },
        { lat: 33.285878, lng: 44.346292 }
      ]
       */
    }
  },
  mounted () {
    this.initMap()
    this.$store.dispatch(`darkstores/${DarkStoresActions.FETCH_DARKSTORES}`, { pageNUmber: null, pageSize: null })
  },
  computed: {
    darkstores () {
      return this.$store.getters['darkstores/getDarkStores']
    },
    driverMarkers: {
      get: function () {
        return this.$store.getters['drivers/getDriversMarkers']
      },
      set: function (newValue: any) {
        this.$store.dispatch(`drivers/${DriverActions.SET_DRIVERS_LOCATIONS_CACHE}`, newValue)
      }
    }
  },
  watch: {
    map: {
      handler: function (dat: any) {
        if (this.driverMarkers) {
          const cached = [] as any
          Object.keys(this.driverMarkers).map((id) => {
            cached.push(this.driverMarkers[id].data)
          })
          this.updateMarkers(cached)
        }
        if (this.driversLocations) {
          // this.setDriverMarkers(this.driversLocations)
          // this.createMarker(this.driversLocations)
          this.updateMarkers(this.driversLocations)
          this.driversLocations = null
        }
        if (this.darkstoresState) {
          this.setDarkstores(this.darkstoresState)
          this.darkstoresState = null
        }
      }
    },
    locations (newVal: any[]) {
      if (wdw.google) {
        setTimeout(() => this.setDriverMarkers(newVal), 500)
      } else {
        this.driversLocations = newVal
      }
    },
    selectedDriver (val: Driver): void {
      // const center = new wdw.google.maps.LatLng(val.lastLocation.latitude, val.lastLocation.longitude)
      // this.map.panTo(center)
      // if (this.activeInfoWindow) {
      //   this.activeInfoWindow.close()
      // }
    },
    darkstores (val) {
      if (wdw.google) {
        setTimeout(() => {
          this.setDarkstores(val)
        }, 500)
      } else {
        this.darkstoresState = val
      }
    },
    selectedDriverId (newDriverId, oldDriverId) {
      // Seçilen sürücü değiştiğinde
      if (newDriverId !== oldDriverId) {
        this.updateSelectedRoute(newDriverId, oldDriverId)
      }
    }
  },
  methods: {
    setDriverMarkers (val: any): void {
      /*
      for (let i = 0; i < driverMarkers.length; i++) {
        driverMarkers[i].setMap(null)
      }
      val.map((el: any) => {
        this.addMarker(el)
      })
       */
      /* const testDriver = [{
        driver_status: {
          name: 'idle',
          value: 'Idle',
          id: 1
        },
        is_busy: 0,
        last_location: {
          latitude: 33.312805,
          longitude: 44.361488
        },
        vehicle_id: {
          model: 'Renault',
          license_no: '78282',
          owner: null,
          is_activated: true,
          name: 'Elentra',
          id: 3,
          make: 'Captur',
          is_assigned: false,
          year: '2020',
          vehicle_type: {
            value: 'Car',
            id: 1
          }
        },
        id: 3
      }]
       */
      if (Object.keys(this.driverMarkers).length) {
        // find existing drivers
        const existDriversId = val.map((driverLocation: any) => driverLocation.id)
        // find nonExisting drivers from cache list
        const nonExistCache = Object.keys(this.driverMarkers)
          .map((k) => this.driverMarkers[k].data)
          .filter(
            ({ id }) => !existDriversId.includes(id)
          )
          .map((ne) => (ne.id))
        // remove nonExisting drivers from cache list
        nonExistCache.forEach(k => delete this.driverMarkers[k])
      }
      this.updateMarkers(val)
    },
    initMap (): void {
      setTimeout(() => {
        if (wdw.google) {
          const latitude = 33.312805
          const longitude = 44.361488
          this.map = new wdw.google.maps.Map(
            document.getElementById('map'),
            {
              center: { lat: latitude, lng: longitude },
              zoom: 15,
              mapTypeControl: false,
              streetViewControl: false,
              disableDefaultUI: false
            }
          )
          this.map.addListener('click', () => {
            if (this.activeInfoWindow) {
              this.activeInfoWindow.close()
            }
          })
        } else {
          this.initMap()
        }
      }, 500)
    },
    /*
    addMarker (driverLocation: any): void {
      if (!driverLocation.last_location.latitude && !driverLocation.last_location.longitude) return
      let isBusy = false
      if (driverLocation.driver_status) {
        isBusy = ['idle', 'occupied_inward', 'occupied_inward_assigned'].includes(driverLocation.driver_status.name)
      }
      const position = new wdw.google.maps.LatLng(driverLocation.last_location.latitude, driverLocation.last_location.longitude)
      const vehicleType = driverLocation.vehicle_id.vehicle_type.id

      const image = {
        url: isBusy ? require(`../../../../assets/vehicle_${vehicleType}_green.png`)
          : require(`../../../../assets/vehicle_${vehicleType}_red.png`),
        size: new wdw.google.maps.Size(55, 70),
        origin: new wdw.google.maps.Point(0, 0),
        anchor: new wdw.google.maps.Point(22, 50)
      }
      let orderCount = 0
      const marker = new wdw.google.maps.Marker({
        position,
        map: this.map,
        icon: image,
        label: {
          text: `${orderCount} Items`,
          className: 'order-map-drivers-order-count',
          color: '#fff',
          fontWeight: 'semibold',
          fontSize: '12px'
        }
      })
      const infowindow = new wdw.google.maps.InfoWindow({
        content: this.getContentData(driverLocation.id)
      })

      marker.addListener('click', (event: any) => {
        if (this.activeInfoWindow) {
          this.activeInfoWindow.close()
        }
        this.activeInfoWindow = infowindow
        infowindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: false
        })
        setTimeout(() => {
          const orderElm = document.getElementById(`order-${driverLocation.id}`)
          const nameElm = document.getElementById(`name-${driverLocation.id}`)
          const vehicleElm = document.getElementById(`vehicle-${driverLocation.id}`)
          const modelElm = document.getElementById(`model-${driverLocation.id}`)
          const phoneElm = document.getElementById(`phone-${driverLocation.id}`)
          if (orderElm) {
            let content: any = ''
            this.$store.dispatch(`drivers/${DriverActions.FETCH_CURRENT_ORDER}`, { driverId: driverLocation.id }).then((orders: Order[]) => {
              if (orders.length) {
                orderCount = orders.length
                orders.map((order: Order) => {
                  content += `<p id="${order.orderId}" class="cursor-pointer text-sm text-blue-700">${order.orderId}</p>`
                })
              } else {
                content += `<p>${this.$t('drivers.noAssignedOrder')}</p>`
              }
              orderElm.innerHTML = content
              orders.map((order: Order) => {
                const item = document.getElementById(order.orderId)
                if (item) {
                  item.addEventListener('click', function () {
                    router.push({ path: '/orders/all', query: { id: order.orderId } })
                  })
                }
              })
            })
          }
          this.$store.dispatch(`drivers/${DriverActions.FETCH_SINGLE_DRIVER}`, driverLocation.id).then((driver: Driver) => {
            if (nameElm) {
              nameElm.innerText = `${driver.driverInfo.userName} - ${driver.driverInfo.firstName} ${driver.driverInfo.lastName}`
            }
            if (vehicleElm) {
              vehicleElm.innerText = `${driver.vehicleId.name}`
            }
            if (modelElm) {
              modelElm.innerText = `${driver.vehicleId.model}`
            }
            if (phoneElm) {
              phoneElm.innerText = `${driver.driverInfo.userPhone}`
            }
          })
        }, 0)
      })
      driverMarkers.push(marker)
    },
     */
    getMarker (driverLocation: any) {
      if (this.markers[driverLocation.id]) {
        return this.markers[driverLocation.id]
      }
      this.createMarker(driverLocation)
      return this.markers[driverLocation.id]
    },
    createMarker (driverLocation: any): void {
      if (!driverLocation.last_location.latitude && !driverLocation.last_location.longitude) return
      let isBusy = false
      if (driverLocation.driver_status) {
        isBusy = ['occupied_outward', 'occupied_inward', 'occupied_inward_assigned'].includes(driverLocation.driver_status.name)
      }
      const position = new wdw.google.maps.LatLng(driverLocation.last_location.latitude, driverLocation.last_location.longitude)
      const vehicleType = driverLocation.vehicle_id.vehicle_type.id

      let statusIcon
      const statusName = driverLocation.driver_status.name
      if (statusName === 'occupied_inward') {
        statusIcon = '_yellow.png' // yellow
      } else if (statusName === 'idle') {
        statusIcon = '_green.png' // green
      } else if (statusName === 'idle_assigned') {
        statusIcon = '_blue.png' // blue
      } else {
        statusIcon = '_red.png' // red
      }

      const image = {
        url: require(`../../../../assets/vehicle_${vehicleType}${statusIcon}`),
        size: new wdw.google.maps.Size(55, 70),
        origin: new wdw.google.maps.Point(0, 0),
        anchor: new wdw.google.maps.Point(22, 50)
      }
      const warnIcon = {
        url: isBusy ? require('../../../../assets/warning-icon.png')
          : require(`../../../../assets/vehicle_${vehicleType}${statusIcon}`),
        size: new wdw.google.maps.Size(55, 70),
        origin: new wdw.google.maps.Point(0, 0),
        anchor: new wdw.google.maps.Point(22, 50)
      }
      const marker = new wdw.google.maps.Marker({
        position,
        map: this.map,
        icon: image,
        label: driverLocation.driver_orders_count ? {
          text: `${driverLocation.driver_orders_count} Items`,
          className: 'order-map-drivers-order-count',
          color: '#fff',
          fontWeight: 'semibold',
          fontSize: '12px'
        } : null
      })
      const infowindow = new wdw.google.maps.InfoWindow({
        content: this.getContentData(driverLocation.id)
      })
      marker.addListener('click', (event: any) => {
        if (this.activeInfoWindow) {
          this.activeInfoWindow.close()
        }
        this.activeInfoWindow = infowindow
        infowindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: false
        })
        this.selectedDriverId = this.selectedDriverId === driverLocation.id ? null : driverLocation.id
        setTimeout(() => {
          const orderElm = document.getElementById(`order-${driverLocation.id}`)
          const nameElm = document.getElementById(`name-${driverLocation.id}`)
          const vehicleElm = document.getElementById(`vehicle-${driverLocation.id}`)
          const modelElm = document.getElementById(`model-${driverLocation.id}`)
          const phoneElm = document.getElementById(`phone-${driverLocation.id}`)
          if (orderElm) {
            let content: any = ''
            this.$store.dispatch(`drivers/${DriverActions.FETCH_CURRENT_ORDER}`, { driverId: driverLocation.id }).then((orders: Order[]) => {
              if (orders.length) {
                orders.map((order: Order) => {
                  content += `<p id="${order.orderId}" class="cursor-pointer text-sm text-blue-700">${order.orderId}</p>`
                })
              } else {
                content += `<p>${this.$t('drivers.noAssignedOrder')}</p>`
              }
              orderElm.innerHTML = content
              orders.map((order: Order) => {
                const item = document.getElementById(order.orderId)
                if (item) {
                  item.addEventListener('click', function () {
                    router.push({ path: '/orders/all', query: { id: order.orderId } })
                  })
                }
              })
            })
          }
          this.$store.dispatch(`drivers/${DriverActions.FETCH_SINGLE_DRIVER}`, driverLocation.id).then((driver: Driver) => {
            if (nameElm) {
              nameElm.innerText = `${driver.driverInfo.userName} - ${driver.driverInfo.firstName} ${driver.driverInfo.lastName}`
            }
            if (vehicleElm) {
              vehicleElm.innerText = `${driver.vehicleId.name}`
            }
            if (modelElm) {
              modelElm.innerText = `${driver.vehicleId.model}`
            }
            if (phoneElm) {
              phoneElm.innerText = `${driver.driverInfo.userPhone}`
            }
          })
        }, 0)
      })
      const lastLocationTime = this.driverMarkers[driverLocation.id]
        ? moment(this.driverMarkers[driverLocation.id].lastLocationTime) : moment()
      this.driverMarkers = {
        ...this.driverMarkers,
        [driverLocation.id]: {
          data: driverLocation,
          lastLocationTime,
          hasSpecialIcon: false,
          icon: image,
          warnIcon
        }
      }
      this.markers[driverLocation.id] = marker
      this.routePaths[driverLocation.id] = [] // empty route
    },
    updateMarkers (driverLocations: any) {
      driverLocations.forEach((driver:any) => {
        const lat = driver.last_location.latitude
        const lng = driver.last_location.longitude
        // const tripItem = this.testTrip.shift()
        // const { lat, lng } = tripItem as any
        if (this.driverMarkers[driver.id]) {
          // const marker = this.driverMarkers[driver.id].marker
          const marker = this.getMarker(driver)
          if (lat === 0 && lng === 0) {
            // remove existing on the map
            toRaw(marker).setMap(null)
            delete this.driverMarkers[driver.id]
            delete this.routePaths[driver.id]
            delete this.markers[driver.id]
          } else {
            const oldData = this.driverMarkers[driver.id].data
            if (oldData.driver_orders_count !== driver.driver_orders_count) {
              // Update label
              this.getMarker(driver).setLabel(driver.driver_orders_count ? {
                text: `${driver.driver_orders_count} Items`,
                className: 'order-map-drivers-order-count',
                color: '#fff',
                fontWeight: 'semibold',
                fontSize: '12px'
              } : '')
            }
            this.driverMarkers[driver.id].data = driver

            // if driver waiting at the same location for 3 mins add special icon
            const hasWarnIcon = oldData.hasSpecialIcon
            if (lat === oldData.last_location.latitude && lng === oldData.last_location.longitude) {
              const lastLocation = this.driverMarkers[driver.id].lastLocationTime
              const diff = moment().diff(lastLocation, 'minutes')
              if (diff >= 3 && !hasWarnIcon) {
                // add special icon
                this.driverMarkers[driver.id].hasSpecialIcon = true
                this.getMarker(driver).setIcon(this.driverMarkers[driver.id].warnIcon)
                this.getMarker(driver).setLabel({
                  text: `${diff} mins`,
                  className: 'order-map-driver-waiting-time',
                  color: '#fff',
                  fontWeight: 'semibold',
                  fontSize: '12px'
                })
              }
            } else {
              // add normal icon
              this.getMarker(driver).setIcon(this.driverMarkers[driver.id].icon)
              this.getMarker(driver).setLabel(null)
              this.driverMarkers[driver.id].hasSpecialIcon = false
              this.driverMarkers[driver.id].lastLocationTime = moment()
            }
            // animate existing
            const newPosition = new wdw.google.maps.LatLng(lat, lng)
            this.animateMarker(marker, newPosition, driver.id)
          }
        } else if (lat !== 0 && lng !== 0) {
          this.createMarker(driver)
        }
      })
      // marker cluster
      if (markerCluster) {
        markerCluster.setMap(null)
      }
      markerCluster = new MarkerClusterer({
        map: toRaw(this.map),
        markers: Object.keys(this.markers).map((id: any) => (toRaw(this.markers[id])))
      })
    },
    animateMarker (marker:any, newPosition:any, driverId:any) {
      const animationDuration = 15000
      const startPosition = marker.getPosition()
      let startTime = 0

      const animateStep = (timestamp:any) => {
        if (!startTime) startTime = timestamp
        const elapsedTime = timestamp - startTime

        if (elapsedTime >= animationDuration) {
          marker.setPosition(newPosition)
          return
        }

        const fraction = elapsedTime / animationDuration
        const interpolatedPosition = wdw.google.maps.geometry.spherical.interpolate(
          startPosition,
          newPosition,
          fraction
        )

        marker.setPosition(interpolatedPosition)
        // this.drawRoute(driverId, startPosition, interpolatedPosition)
        this.updateRoutePath(driverId, interpolatedPosition)
        requestAnimationFrame(animateStep)
      }

      requestAnimationFrame(animateStep)
    },
    /*
    drawRoute (driverId:any, startPosition:any, endPosition:any) {
      if (!this.routePaths[driverId]) {
        this.routePaths[driverId] = []
      }
      this.routePaths[driverId].push(endPosition)

      const route = new wdw.google.maps.Polyline({
        path: this.routePaths[driverId],
        geodesic: true,
        strokeColor: '#3d3c3c',
        strokeOpacity: 1.0,
        strokeWeight: 2
      })

      route.setMap(this.map)
    },
     */
    updateRoutePath (driverId:any, position:any) {
      if (!this.routePaths[driverId]) {
        this.routePaths[driverId] = []
      }
      this.routePaths[driverId].push(position)

      if (this.selectedDriverId === driverId) {
        this.updateSelectedRoute(driverId, driverId)
      }
    },
    updateSelectedRoute (newDriverId:any, oldDriverId:any) {
      if (oldDriverId && this.routePaths[oldDriverId] && (!newDriverId || newDriverId !== oldDriverId)) {
        const oldPolyline = this.polylines[oldDriverId]
        toRaw(oldPolyline).setMap(null)
        delete this.polylines[oldDriverId]
      }

      if (newDriverId && this.routePaths[newDriverId]) {
        const poly = this.polylines[newDriverId]
        if (!poly) {
          const newPolyline = new wdw.google.maps.Polyline({
            path: this.routePaths[newDriverId],
            strokeColor: '#3d3c3c',
            strokeOpacity: 1.0,
            strokeWeight: 2
          })
          newPolyline.setMap(this.map)
          this.polylines = {
            ...this.polylines,
            [newDriverId]: newPolyline
          }
        } else {
          poly.setPath(this.routePaths[newDriverId])
        }
      }
    },
    addDarkStoreMarker (lat: number, lng: number): any {
      const position = new wdw.google.maps.LatLng((lat || 0), (lng || 0))
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const markerImg = require('../../../../assets/markers/darkstore-dark-marker.png')
      const image = {
        url: markerImg,
        size: new wdw.google.maps.Size(55, 70),
        origin: new wdw.google.maps.Point(0, 0),
        anchor: new wdw.google.maps.Point(22, 67)
      }
      const marker = new wdw.google.maps.Marker({
        position,
        map: this.map,
        icon: image
      })
      // marker.setLabel({ text: 'DS', color: 'white' })
      return marker
    },
    getContentData (driverId: number): any {
      return `<table>
           <tbody>
            <tr>
                <th>${this.$t('drivers.driver')}</th>
                <td id="name-${driverId}"></td>
            </tr>
            <tr>
                <th>${this.$t('drivers.vehicleType')}</th>
                <td id="vehicle-${driverId}"></td>
            </tr>
            <tr>
                <th>${this.$t('drivers.vehicleModel')}</th>
                <td id="model-${driverId}"></td>
            </tr>
            <tr>
                <th>${this.$t('drivers.orders')}</th>
                <td id="order-${driverId}"></td>
            </tr>
            <tr>
                <th>${this.$t('drivers.phone')}</th>
                <td id="phone-${driverId}"></td>
            </tr>
           </tbody>
        </table>`
    },
    setDarkstores (val: any): void {
      val.map((darkStore: DarkStoreInterface) => {
        const marker = this.addDarkStoreMarker(darkStore.latitude, darkStore.longitude)
        const infowindow = new wdw.google.maps.InfoWindow({
          content: darkStore.name
        })
        marker.addListener('click', () => {
          if (this.activeInfoWindow) {
            this.activeInfoWindow.close()
          }
          this.activeInfoWindow = infowindow
          infowindow.open({
            anchor: marker,
            map: this.map
          })
        })
        return marker
      })
    }
  }
})
